
.add-page {
	height: 100%;
	background: #F8F9FA;
    font-size: 14px;
	.page-from{
        margin: 7px 15px;
        padding: 0 15px;
        background: #ffffff;
        border-radius: 4px;
        .item{
            display: flex;
            justify-content: flex-start;
            align-items: center;
            height: 48px;
            box-sizing: border-box;
            border-bottom: 1px solid #E6E6E6;
            font-size: 15px;
            color: #333333;
            position: relative;
            .tage{
                width: 82px;
                font-weight: bold;
            }
            .content{
                height: 100%;
                flex: 1;
                .ipt{
                    height: 100%;
                    width: 100%;
                    background: none;
                }
            }
            .icon-right{
                display: block;
                width: 14px;
                height: 14px;
                background: url(../../assets/icons/icon-right.png) center/100%,100% no-repeat;
                position: absolute;
                top: 18px;
                right: 4px;
            }
        }
        .item:last-child{
            border: none;
        }
    }
    .big-btn{
        margin-top: 50px;
    }
}
